import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Emr);

export default {
    //Get detail episodeID
    getEpisodeDetail(episodeID) {
        var query = {
            episodeID
        }
        return httpClient.get(`/Episode/Get/{episodeID}`, query);
    },
    getEpisodeDetailByEpisodeRecord(episodeRecordID) {
        return httpClient.get(`/Episode/GetByEpisodeRecord/${episodeRecordID}`);
    },
    // Update episode when all episodeRecord already finished
    updateFinishStatusEpisode(episodeRecordID) {
        var query = {
            episodeRecordID,
        }
        return httpClient.put(`/Episode/UpdateStatus/${episodeRecordID}`, query);
    },
}