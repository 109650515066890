<template>
  <div fluid class="examination-form">
    <div fluid class="ma-0 pa-0 mt-2">
      <component
        v-for="comp in listComponents"
        :key="comp"
        :is="comp"
        :dataPoints="dataPoints"
        style="margin-top: -1px"
      />
    </div>
  </div>
</template>

<script>
import FormService from "@/services/manageForm";

export default {
  props: {},
  data() {
    return {
      formDetail: {},
      dataPoints: [],
      listComponents: [],
    };
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    // render from component layout
    async renderFormLayout(formID) {
      if (!formID) return;
      var result = await FormService.getForm(formID);
      if (!result || result.error) {
        this.showError(
          "Cannot get form detail at the moment! Please try again later."
        );
      }
      this.formDetail = result;
      var { components } = result;
      if (components == "*") {
        this.listComponents = ["GeneralExamComponent"];
        return;
      }
      components = components.split(",");
      this.listComponents = [];
      components.forEach((comp) => {
        if (this.EhrComponents.includes(comp)) {
          this.listComponents.push(comp);
        }
      });
    },
    // render data points
    loadDataPoints(dataPoints = []) {
      this.dataPoints = dataPoints;
    },
  },
};
</script>