<template>
  <div class="float-left form-data justify-center">
    <div class="fixed-section-exam">
      <p class="staff-label" v-if="orderItemValidation.staffOrderName">
        <span>{{ $t("examination.staffOrder") }}:</span>
        <b>{{ orderItemValidation.staffOrderName }}</b>
      </p>
      <p class="staff-label" v-if="orderItemValidation.staffPerformName">
        <span>{{ $t("examination.staffPerform") }}:</span>
        <b>{{ orderItemValidation.staffPerformName }}</b>
      </p>
      <v-btn
        v-if="orderItemValidation.isAllowCancel"
        @click="cancelCurrentOrderItem"
        class="pl-5 pr-5"
        outlined
        color="red"
      >
        {{ $t("common.cancelStep") }}
      </v-btn>
    </div>
    <h3 class="mb-5 text-center">{{ axpx.description }}</h3>
    <ExaminationForm ref="ExaminationForm" />

    <DialogConfirm ref="DialogConfirm" :message="dialogMessage" />
    <v-bottom-navigation
      grow
      height="50"
      absolute
      class="elevation-0 exam-bottom-controller"
      hide-on-scroll
    >
      <v-btn
        link
        v-if="$route.query.orderID"
        class="pl-10 pr-10 white--text"
        @click="saveDataPointsAndCompleteOrderItem"
        v-bind:disabled="isFinishExamination === true ? true : false"
      >
        {{ $t("common.SaveData") }}
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import ExaminationForm from "@/components/examination/ExaminationForm.vue";
import WorkListService from "@/services/work-list";
import DataPointService from "@/services/dataPoint";
import EpisodeService from "@/services/episode";
import AdmissionService from "@/services/admission";
import DialogConfirm from "@/components/DialogConfirm";
import i18n from "@/plugins/i18n";

export default {
  name: "ExaminationPage",
  components: {
    ExaminationForm,
    DialogConfirm,
  },
  data() {
    return {
      dialogMessage: "",
      episodeRecordID: this.$route.params.episodeRecordID,
      orderItemValidation: {
        isAllowCancel: false,
        staffPerformName: "",
        staffOrderName: "",
      },
      dataPoints: [],
      axpx: {},
      episodeID: null,
      isFinishExamination: false,
    };
  },
  // when examination page is first loaded
  async created() {
    // get data point via APIs only once
    await this.getEpisodeRecordDataPoints();
    // then render form layout and load data points
    this.renderFormLayoutAndLoadDataPoints();
    await this.getEpisodeID(this.episodeRecordID);
    this.validateCancelCurrentOrderItem();
  },
  watch: {
    // when form layout changed only
    "$route.query.orderID": {
      async handler() {
        await this.getEpisodeRecordDataPoints();
        // then render form layout and load data points
        this.renderFormLayoutAndLoadDataPoints();
        this.validateCancelCurrentOrderItem();
      },
    },
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    showSuccess(message) {
      this.$toast.success(message);
    },
    // function to validate if the current orderItem could be canceled
    async validateCancelCurrentOrderItem() {
      // reset orderItem validation model
      this.orderItemValidation = {
        isAllowCancel: false,
        staffPerformName: "",
        staffOrderName: "",
      };
      var orderID = this.$route.query.orderID;
      if (!orderID) return;
      // get the new validation model via API
      this.orderItemValidation = await AdmissionService.validateBeforeDeleteOrderItem(
        orderID
      );
    },
    // function to handle load form layout via components
    // and load cached data-points into it
    async renderFormLayoutAndLoadDataPoints() {
      // get name of the form
      this.renderFormName();
      // save and cache current data-points
      this.dataPoints = JSON.parse(JSON.stringify(this.dataPoints));
      // 1. render all form components
      var formID = this.$route.query.formID;
      await this.$refs.ExaminationForm.renderFormLayout(formID);
      // 2. Then, load the data points pre-loaded into the component
      // component update data-point internally
      // updated data point get cached back into the dataPoints array => used for API call later
      await this.$refs.ExaminationForm.loadDataPoints(this.dataPoints);
    },
    // this function save data-points collection
    // then complete the order item selected
    async saveDataPointsAndCompleteOrderItem() {
      var orderID = parseInt(this.$route.query.orderID);
      var episodeRecordID = this.$route.params.episodeRecordID;
      var patientID = this.$route.params.patientId;

      var dataPoints = this.dataPoints;
      // update collection data-points
      await DataPointService.postCollection(
        episodeRecordID,
        patientID,
        dataPoints
      );
      // complete the order with status = 3
      await WorkListService.completeOrderItem(orderID);
      this.$emit("onOrderCompleted", orderID);
      this.showSuccess(i18n.t("message.examination.saveDatapoint"));
      this.orderItemValidation.isAllowCancel = false;
      this.sockets.common.invoke("CompleteOrderItem", orderID);
    },
    // this function get all data-points and cached into array
    async getEpisodeRecordDataPoints() {
      var episodeRecordID = this.$route.params.episodeRecordID;
      if (!episodeRecordID) return;
      var result = await DataPointService.searchDataPoint(episodeRecordID);
      if (!result || result.error) {
        this.showError(
          "Cannot get form data-point at the moment! Please try again later."
        );
        return;
      }
      this.dataPoints = JSON.parse(JSON.stringify(result.items));
      console.log(this.dataPoints);
    },
    // get order info
    async renderFormName() {
      var targetID = this.$route.query.targetID;
      var result = await WorkListService.getAxPxByTargetID(targetID);
      if (!result || result.error) {
        this.showError(
          "Cannot get order info at the moment! Please try again later."
        );
        return;
      }
      this.axpx = result;
    },
    async getEpisodeID(episodeRecordID) {
      if (!episodeRecordID) return;
      var result = await WorkListService.GetByEpisodeRecordID(episodeRecordID);
      if (!result || result.error) {
        this.showError(
          "Cannot get episodeID at the moment! Please try again later."
        );
      }
      this.episodeID = result.episodeID;
      await this.getEpisodeInfo();
    },
    async getEpisodeInfo() {
      const result = await EpisodeService.getEpisodeDetail(this.episodeID);
      if (!result || result.error) {
        this.showError(
          "Can not get episode information. Please try again later."
        );
        return;
      }
      if (result.statusID == 2) {
        this.isFinishExamination = true;
      }
    },
    async cancelCurrentOrderItem() {
      this.dialogMessage = "Are you sure you want to cancel this step?";
      if (!(await this.$refs.DialogConfirm.confirm())) return;
      var orderID = this.$route.query.orderID;
      var episodeRecordID = this.$route.params.episodeRecordID;
      var result = await AdmissionService.removeOrderItemFromEpisodeRecord(
        episodeRecordID,
        [orderID]
      );
      if (!result || result.error) {
        this.showError(
          "Cannot remove order item at the moment! Please try again later."
        );
        return;
      }
      window.dispatchEvent(new Event("onCancelOrderItemSuccess"));
      this.showSuccess(i18n.t("message.examination.cancelCurrentOrder"));
    },
  },
};
</script>

<style lang="scss">
.exam-bottom-controller {
  background-color: #f5f5f5 !important;
  button {
    background-color: #0277bd !important;
    .v-btn__content {
      color: white !important;
      font-size: 120%;
      text-transform: uppercase;
    }
  }
  button:disabled {
    background-color: #bdbdbd !important;
    .v-btn__content {
      color: #424242 !important;
    }
  }
}
.form-data {
  width: 100%;
}
.fixed-section-exam {
  float: right;
  margin-top: -50px;
  > p.staff-label {
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    > span {
      min-width: 100px;
      display: inline-block;
      text-align: right;
      margin-right: 8px;
      margin-top: 2px;
    }
  }
  > .v-btn {
    margin-top: 12px;
  }
}
.v-item-group.v-bottom-navigation {
  display: flex;
  width: calc(100% - 16px) !important;
  bottom: 15px;
}
.nav-btn-fixed {
  position: fixed;
}
</style>
