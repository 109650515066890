import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
var httpClient = new HttpClient(ApiGateway.Emr);

const prefix = '/Ehr/DataPoint';

export default {
  postCollection: (episodeRecordID, patientID, dataPoints) => {
    var body = {
      episodeRecordID,
      patientID,
      dataPoints,
    }
    return httpClient.post(`${prefix}/Collections`, {}, body)
  },
  searchDataPoint: (episodeRecordID, keySearch = "") => {
    var query = {
      episodeRecordID,
      keySearch
    }
    var headers = {
      page: 1,
      limit: 10000,
    }
    return httpClient.get(`${prefix}/Search`, query, headers)
  },
}